$nav-height: 60px;

.nav-bar {
    height:$nav-height;
    width:100%;
    position:fixed;
    background:$white;
    z-index:2;
    transition:background .3s;
    padding:5px 0;
}
.nav-logo {
    height:50px;
    padding:7px 0
}
.nav-overlay {
    background:#FDC23F;
    height:100vh;
    width:100%;
    position:absolute;
    top:0;
    right:-100%;
    transition:all .3s;
    ul {
        margin:100px 0 0 0;
    }
    .nav-overlay-item  {
        font-size:45px;
        text-align:right;
	margin-right:30px;
        list-style:none;
        font-family: "recoleta"
    }
    .nav-overlay-sm {
        text-align:center;
        margin:auto;
        justify-content:center;
    }
    &.show {
        right:0;
        transition:all .3s;
    }
}
.hamburger-menu {
    position:absolute;
    right:10px;
    top:9px;
    width:35px;
    z-index:3;
}
.green-blob {
    position: absolute;
    bottom:0;
    width:100%;
    right:-10%;
    width:400px;
}
