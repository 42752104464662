$gray: #1e2327;
$white: #fffafa;
$cream: #FDC23F;
$light-navy: #afdedc;
$navy: #312e45;
$text-gray: #303030;

$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    2: 2,
    3: 3,
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    25: 25,
    30: 30,
    35: 35,
    40: 40,
    45: 45,
    50: 50,
    60: 60,
    75: 75,
    90: 90,
    100: 100,
    125: 125,
    150: 150,
  ),
  $spacers
);

$EMspacers: () !default;
$EMspacers: map-merge(
  (
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    5: 5,
  ),
  $EMspacers
);

@each $prop, $abbrev in (margin: margin, padding: padding) {
  @each $size, $value in $spacers {
    #{bem-b($abbrev)}--#{$size} {
      #{$prop}: #{$value}px;
    }
    #{bem-b($abbrev)}-top--#{$size} {
      #{$prop}-top: #{$value}px;
    }
    #{bem-b($abbrev)}-right--#{$size} {
      #{$prop}-right: #{$value}px;
    }
    #{bem-b($abbrev)}-bottom--#{$size} {
      #{$prop}-bottom: #{$value}px;
    }
    #{bem-b($abbrev)}-left--#{$size} {
      #{$prop}-left: #{$value}px;
    }
  }
}
