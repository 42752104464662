.testimonial-wrap {
  background: $white;
  text-align: center;
  margin-top: 30px;
}
.testimonial-project {
  text-align: center;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  color:$navy;
  .client-circle {
    height: 50px;
    width: 50px;
    margin: auto 20px auto auto;
    flex-grow: 0;
    border-radius: 50%;
    div {
      width: 50px;
      float: right;
    }

  }
  .client-text {
    width:700px;
    max-width:100%;
    margin:auto
}
  .client-info-wrap {
    width: 320px;
    margin: 20px auto auto;
    align-items: center;
  }
  .client-quote {
    font-size: 17px;
    line-height: 30px;
    &:before {
      content: "\201c";
      font-size: 450px;
      position: absolute;
      transform: translate(-30%, 200%);
      color: rgba(0, 0, 0, 0.1);
    }
  }
  .client-name {
    margin: -5px 0 0 0;
    display: inline;
    text-align: left;
    flex-grow: 1;
  }
}
