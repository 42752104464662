.corner-plants-top-right {
        position: absolute;
    right: 0;
    width:400px;
    margin-top: -50px;
}
.corner-plants-bottom-left {
    position: absolute;
left: 0;
z-index: 99999;
width:250px;
}
.corner-plants-top-left {
    position: absolute;
left: -20px;
top:10px;
width:150px;
}
.corner-plants-left {
    position: absolute;
left: 0;
z-index: 99999;
width:220px;
}
.corner-plants-right {
    position: absolute;
right: 0;
bottom:0;
z-index: 99999;
width:220px;
}