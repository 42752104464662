.blocks-wrap {
    display:flex
}
#{bem-b(blocks-subheading)} {
    text-align: center;
    width:500px;
    max-width: 90%;
    margin:auto auto 40px;
}
#{bem-b(blocks-item)} {
    flex: 1 0 0;
    background:$white;
    color:$navy;
    margin:0 15px;
    padding:20px 40px;
    border-radius: 5px;
    height: auto;
    min-height: 350px;
    position: relative;
    text-align: left;
    box-shadow:2px 2px 10px rgba(0, 0, 0, 0.1)
}
#{bem-b-e(blocks-item, content)} {
    font-size:16px;
    margin-top: 20px;
}
#{bem-b-e(blocks-item, button)} {
    position: absolute;
    bottom:40px;
}
#{bem-b-e(blocks-item, header)} {
    margin-bottom:35px;
    font-size:1.5rem;
    font-weight: 600;
    line-height: 2rem;
}
