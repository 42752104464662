footer {
background:$navy;
height:200px;
color:$white;
width:100%;
position: relative;
}
.footer-logo {
  background: $navy;
  padding:5px;
  border-radius: 20px;
  width:100px;
  height:100px;
  margin: auto;
  transform: translate(0, -50%);
  position:relative;
  &:after {
      content: "";
      background:url("../images/alt-logo.png") no-repeat;
      height:60px;
      width:60px;
      position:absolute;
      background-size:contain;
      margin:5px 0 0 15px
  }
}
.copyright-wrap {
  bottom:5px;
  font-size: 12px;
  text-align: center;
  width: 100%;
  position: absolute;
}
