@mixin hover-shadow($color) {
  &:hover {
      box-shadow: 3px 3px 0 $color!important
  }
}
@mixin shadow($color) {
      box-shadow: 3px 3px 0 $color!important
  }
@mixin border($size, $style, $color, $direction: null) {
    @if $direction == null {
      border: $size $style $color;
    } @else {
      border-#{$direction}: $size $style $color;
    }
  }
  
  @mixin transition($speed) {
    transition: $speed;
  }
  @mixin border-radius($top-left, $top-right, $bottom-right, $bottom-left) {
    border-top-left-radius: $top-left;
    border-top-right-radius: $top-right;
    border-bottom-right-radius: $bottom-right;
    border-bottom-left-radius: $bottom-left;
  }
  
