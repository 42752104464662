header {
    z-index:10;
  width: 100%;
  position: relative;
}
nav {
  display: flex;
  justify-content: space-between;
}
.nav-link-wrap {
  margin-left: 20px;
  align-items: center;
}
.nav-link {
  margin: 0 10px;
  text-transform: lowercase;
  font-weight: 500;
  letter-spacing: 0.04em;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  padding: 15px;
  width: auto;
  text-align: center;
  &:after {
    content: " ";
    position: absolute;
    top: 15px;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
  }
  &:hover {
    transition: all 0.2s;
    &:after {
      content: " ";
      position: absolute;
      top: 10px;
      height: 5px;
      width: 15px;
      border-radius: 10px;
      background: $navy;
      transition: all 0.2s;
      transform: translate(-50%, -50%);
    }
  }
}

.header-body {
    margin-top:100px;
    height: auto;
  min-height:500px;
  position: relative;
  .image-wrap {
    margin-bottom: 40px;
  }
  .header-text {
    font-size: 18px;
    line-height: 25px;
    font-weight:400;
  }
  .form-label {
    font-weight: 700;
    margin-bottom: -5px;
  }
  .header-body-email {
    margin: 10px auto 10px;
    input {
      padding: 10px 15px 15px;
      border: none;
      border-radius: 5px;
      background: #e9e9e9;
      width: 100%;
      transition: all 0.2s;
      &::placeholder {
        margin-top: 10px;
      }
      &:focus, &:active {
        outline-color: white;
        outline: 0;
        transition: all 0.2s;
        @include shadow($navy);
      }
    }
    input[type="submit"] {
      margin-left: 15px;
      padding: 10px 20px 15px;
      width: 100px;
      background: $light-navy;
      cursor: pointer;
      transition: all 0.2s;
      font-weight: 700;
      &:hover {
        transition: all 0.2s;
        @include shadow($navy);
      }
    }
  }
}
