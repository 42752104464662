.project-background {
  background: $navy;
  padding-top: 100px;
}
.project-container {
  margin-top: -200px;
}
.projects-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;
}
.project-overlay {
  height: 100%;
  width: 100%;
  background: $navy;
  border-radius: 5px;
  position: absolute;
  opacity: 0;
  top: 0;
}
.project {
  height: 275px;
  margin: 20px;
  border-radius: 5px;
  flex: 1 0 40%;
  text-align: center;
  position: relative;
  @include transition(all 0.4s);
  .project-title {
    color: transparent;
    text-decoration: none;
    @include transition(all 0.4s);
    margin-top: 15px;
    font-size: 2em;
  }
  .project-link {
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 0;
    opacity: 0;
    border: none;
    @include transition(all 0.4s);
    bottom: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    box-shadow: 0 0 10px rgba(30, 30, 30, 0.15);
    transition: all 0.5s;
    cursor: pointer;
    .project-overlay {
      opacity: 0.6;
      @include transition(all 0.4s);
    }
    .project-title {
      color: white;
      text-decoration: none;
      margin-top: 20px;
      z-index: 1;
      position: relative;
    }
    .project-link {
      position: absolute;
      transform: translate(-50%, 0);
      bottom: 30px;
      opacity: 1;
      z-index: 1;
      border-radius: 5px;
      &:hover {
        color: $navy;
        background: $white;
        border-radius: 5px;
      }
    }
  }
}

.project.nourish {
  background-size: cover;
  background-color: $navy;
}
