* {line-height: 1.4;
   font-size: 16px;}

@import  "_functions", "mixins/_mixins", "_variables", "_bootstrap", "_bem-styles", "_typography", "_four-o-four", "_buttons";

html {
    overflow-x:hidden!important;
}
body {background:$white;}
* {
    font-family: "poppins"
}
img {
    margin:0;
    padding:0;
}

main {
    padding-top: 50px;
    background: $white;
    padding-bottom: 200px;
    overflow: hidden;
}

.container {
    margin: 0 auto;
    width: 1160px;
    max-width: calc(100% - 2.5rem);
    padding: 0 1.25rem;
    position:relative
}

.container-full {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
}

.container-inner {
    margin: 0 auto;
    width: 960px;
    max-width: 100%;
}

.main-cta {
    min-height: 700px;
    background:$navy;
    width: 100%;
    color: $white;
    padding: 100px 0;
}

.wave-top {
    transform: translate(0, 98%);

    .cls-1 {
        fill: $white;
    }
}

.wave-bottom {
    transform: translate(0, 0);
    background: $white;

    .cls-1 {
        fill: $navy;
    }
}

.our-process {
    align-items: center;

    .our-process-info {
        align-self: center;
    }
}

.divider {
    margin: 5em auto;
    text-align: center;
    &.dark {
        .line {
            height: 4px;
            width: 300px;
            border-radius: 5px;
            background: $navy;
            margin: auto;
            margin-bottom: 5px;
        }

        .small-line {
            height: 4px;
            width: 200px;
            border-radius: 5px;
            background: $navy;
            margin: auto;
            margin-bottom: 5px;
        }

        .dot {
            height: 4px;
            width: 80px;
            border-radius: 5px;
            margin: auto;
            background: $navy;
            margin-bottom: 5px;
        }
    }
    &.light {
        .line {
            height: 4px;
            width: 300px;
            border-radius: 5px;
            background: $white;
            margin: auto;
            margin-bottom: 5px;
        }

        .small-line {
            height: 4px;
            width: 200px;
            border-radius: 5px;
            background: $white;
            margin: auto;
            margin-bottom: 5px;
        }

        .dot {
            height: 4px;
            width: 80px;
            border-radius: 5px;
            margin: auto;
            background: $white;
            margin-bottom: 5px;
        }
    }
}

@import "_header", "_footer", "_testimonial",  "modals", "projects", "customer-logos", "blocks", "_workPage", "random-images", "_navigation", "_featured-work";
