#{bem-b(button-wrap)} {
  display: inline-block;
  text-align: left;
}
#{bem-b(button)} {
    font-family:"poppins";
    padding: 10px 20px;
  min-width: 100px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
  letter-spacing: .6px;
  border-radius: 5px;
  border: none;
  display:inline-block;
  text-align: center;
  @include transition(0.3s);
  font-size: 15px;
  &:hover {
    text-decoration: none;
  }
}

#{bem-b-m(button, primary)} {
  background: $light-navy;
  color: $navy;
  @include transition(0.3s);
  &:hover {
    @include transition(0.3s);
    @include hover-shadow($white);

  }
}

#{bem-b-m(button, secondary)} {
  background: transparent;
  padding: 7px 17px;
  color: $light-navy;
  border: 3px solid $light-navy;
  @include transition(0.3s);
  &:hover {
    @include transition(0.3s);
    background: $light-navy;
    color: $navy;
  }
}
#{bem-b-m(button, dark)} {
  background: $navy;
  color: $light-navy;
  @include transition(0.3s);
  &:hover {
    transition: all 0.2s;
    @include hover-shadow($white);
  }
}

#{bem-b-m(button, ghost)} {
  background: transparent;
  padding: 7px 17px;
  color: $navy;
  @include border (3px, solid, transparent);
  @include transition(0.3s);
  &:hover {
    @include transition(0.3s);
    @include border (3px, solid, $navy);

  }
}

#{bem-b-m(button, small)} {
  padding: 4px 10px;
}

#{bem-b-m(button, grow)} {
width: 100%;
}
