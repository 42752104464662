.featured-work {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: pan-y pinch-zoom;
}

.carousel-container {
    width: 80%;
    height: 100%;
    position: relative;
    overflow: hidden;
    touch-action: none;
}

.carousel-card {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(
	to top,
	rgba(0, 0, 0, 0.6),
	rgba(0, 0, 0, 0)
    );
}

.card-overlay h2 {
    color: white;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}

.nav-button {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $navy;
    font-size: 24px;
    transition: background 0.3s ease;
    z-index: 10;
}

.nav-button:hover {
    background: rgba(255, 255, 255, 0.3);
}

.prev {
    left: 20px;
}

.next {
    right: 20px;
}
