.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 2000px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99998;
  overflow: scroll;
  transition: all 1s;
}

.modal-scroll-wrap {
  overflow: scroll;
  position: absolute;
  min-height: 100vh;
  height: auto;
  width: 100%;
}

.modal-main {
  position: absolute;
  background: $white;
  min-height: 3000px;
  height: auto;
  bottom: 100px;
  width: 80%;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99999;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 100px;
}
.modal-closeout {
  height: 50px;
  width: 50px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  &:after {
    content: "X";
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: $light-navy;
    top: 20px;
    right: 20px;
    color: $navy;
    cursor: pointer;
  }
  &:focus,
  &:focus-within,
  &:active {
    outline: 2px solid red !important;
  }
}
.modal-header {
  background: url("https://www.auburnroadvineyards.com/wp-content/uploads/2018/11/enoteca-paint-2-2560x1135.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  height: 300px;
  h1 {
    top: 50%;
    position: relative;
    transform: translate(0, -50%);
    color: $white;
    text-shadow: 0 0 5px rgba(30, 30, 30, 0.2);
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
  }
}
.modal-body {
  padding: 30px 15%;
  width: 100%;
  text-align: center;
  .info-wrap {
    width: 100%;
    text-align: left;
  }
}
.client-site-image-wrap {
  margin: 50px auto;
  background: red;
  width: 700px;
  box-shadow: 0 0 10px rgba(20, 20, 20, 0.2);
  .client-site-img {
    width: 700px;
    height: 2000px;
    margin: auto;
  }
}
.display-block {
  display: block;
  transition: all 0.3s;
}

.display-none {
  display: none;
  transition: all 0.3s;
}
.overlay {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.signup-modal-wrap {
  width: 800px;
  max-width: 90%;
  background-color: $white;
  text-align: center;
  padding: 30px;
  margin: auto;
  position: relative;
  transform: translate(0, 50%);
  border-radius:10px;
  input {
    padding: 10px 15px 15px;
    border: none;
    border-radius: 5px;
    background: #e9e9e9;
    width: 300px;
    transition: all 0.2s;
    &::placeholder {
      margin-top: 10px;
    }
    &:focus {
      outline-color: white;
      outline: 0;
      transition: all 0.2s;
      box-shadow: 3px 3px 0 $navy;
    }
  }
  input[type="submit"] {
    margin-left: 15px;
    padding: 10px 20px 15px;
    width: auto;
    color: $navy;
    background: $light-navy;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 700;
    &:hover {
      transition: all 0.2s;
      box-shadow: 5px 5px 0 $navy;
    }
  }
}
.signup-modal-content {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 400px;
  &:input {
    flex: 1 0 0;
  }
}
.signup-modal-inner {
  margin: auto;
  width: 600px;
  max-width: 90%;
}
