// flex items

#{bem-b(flex)} {
  display: flex;
}
#{bem-b-e(flex, item)} {
  flex: 1 0 0;
}

#{bem-b(position-absolute)} {
  position: absolute;
  margin: 0;
  padding: 0;
}
#{bem-b(position-relative)} {
  position: relative;
  margin: 0;
  padding: 0;
}

///width///


#{bem-b(w-100)} {
  width:100%;
}