.customer-logo-wrap {
  background:$white;
  border-top: 10px solid fade-out($color: $navy, $amount: .9);
  border-bottom: 10px solid fade-out($color: $navy, $amount: .9);
  margin-top:50px;
  .customer-logo {
      width:100px;
      height: 100px;
      text-align:center;
    img {
      margin:auto;
    }
  }
}
.title {
  font-size:3em;
  text-align: left;
  font-weight: 600;
  padding:0 25px
}

.mover-1 {
  width: max-content;
  animation: moveSlideshow 50s linear infinite;
}

.mover-1 img {
  display:inline-block;
  vertical-align:middle;
  width:100px;
  margin:0;
}

@keyframes moveSlideshow {
  100% { 
    transform: translateX(-2128px);  
  }
}
