@font-face {
  font-family: "Recoleta";
  font-weight: normal;
  src: url("../fonts/Recoleta-Medium.otf") format("opentype");
}
@font-face {
  font-family: "poppins";
  font-weight: normal;
  src: url("../fonts/Poppins-Regular.ttf") format("truetype"),
       url("../fonts/Poppins-Medium.ttf") format("truetype"),
       url("../fonts/Poppins-Bold.ttf") format("truetype")
}

body,
h3,
h4,
h5,
h6,
p,
button,
li,
a {
  font-family: "Avenir Next";
  font-weight:400;
  text-decoration: none;
}

h1, h2, h3 {
    font-family:"Recoleta";
}
h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 0.2em;
}
h2 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0.2em;
}
h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.2em;
}
